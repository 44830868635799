<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="">
                        <div class="">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="product-detail">
                                <div class="row">
                                  <div class="col-md-12 col-2">
                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product1"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1594603912_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product2"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1536137512_PGB-700-XYZ-TABLE-1.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product3"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1536137522_PGB-700-AB-TANK.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product4"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1536137531_PGB-700-Hand-control.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product5"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1536137540_PGB-700-PLC.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="wehed()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1594603912_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="thnine()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1536137512_PGB-700-XYZ-TABLE-1.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="tletha()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1536137522_PGB-700-AB-TANK.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="arb3a()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1536137531_PGB-700-Hand-control.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="khamssa()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1536137540_PGB-700-PLC.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end product img -->
                            </div>
                          </div>
                          <!-- end row -->
                        </div>
                      </div>
                      <!-- end card -->
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="mt-1 col-11">
                    <div class="row">
                      <h1 class="font-size-48 mb-3">
                        <strong>
                          AB part Glue dispenser Machine PGB-700
                        </strong>
                      </h1>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Brand :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >D&H</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Model :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >PGB-700</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Applications :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >Capacitor, Power Supply, Transformer, Ignition Coil,
                          Reverse Sensor, Circuit Board, Electronic Ballast, Art
                          Stickers , Other Electronic Products Where Glue
                          Potting Demanded.</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Suitable Material :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >AB part glue , silicone , epoxy resin,
                          polyurethane</span
                        >
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 450px; height: 250px"
                            title="YouToube Video"
                            src="https://www.youtube.com/embed/xk0zpK5h6BE"
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <h1 style="color: black; font-weight: 700">Features</h1>
                        <br />

                        <div class="row" style="font-size: 14px">
                          Machine description and working principle
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          PGB-700 system accurately meters, mixers and dispenses
                          two component medium to low viscosity materials for
                          potting gasketing, sealing, encapsulation and syringe
                          filling. The system ratio form 1:1 t0 10:1.
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          The system is composed of material tank, metering
                          pump, metering motor, material pipe, two component
                          valve, motor for dynamic stirring, mixing tube and 3
                          axis robotic arms. Two kinds of glue are separated
                          before they mix in mixing tube, so the glue will not
                          cure in devices. Glue valve is with vacuum function to
                          keep thin fluid from dripping. Other optional
                          functions include:
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          Tank stirring function; <br />Tank defoaming function;
                          <br />Thermostat heating function on Tank, pipe, and
                          valve;
                        </div>

                        <br />
                      </div>
                      <!-- end col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <br /><br />

            <br />
            <b-tabs pills card style="font-size: 18px">
              <b-tab active title="Technical Data">
                <div class="row" style="font-size: 14px">
                  Features: <br /><br />

                  1. Automatic metering and mixing, touch screen PLC control
                  system, English operation interface allow easy to set
                  parameters;<br />
                  2. Big LCD screen displays various processing data;<br />
                  3. Easy programming of dots, stripes, arcs, compound arcs and
                  patterns on different planes;<br />
                  4. Gluing speed and timing can be set;<br />
                  5. CAD file can be imported and turned to be dispensing
                  patterns, and downloaded via USB; (optional function)<br />
                  6. Mixing ratio 1:1-1:10 and gluing volume can be set and
                  adjusted as per actual need. Dynamic mixing ensure two
                  component glue to be mixed to a fully homogeneous
                  consistency;<br />
                  7. Metering, mixing and gluing simultaneously;<br />
                  8. Two tanks keep A glue and B glue separately;<br />
                  9. Liquid level sensor, alarm function; <br />
                  10. Tank stirring and vacuum defoaming function optional;<br />
                  11. Auto cleaning; The cleaning mode can be manually or
                  automatically, manual mode just remove the mixing tube then
                  soak into cleaning agent, after 10 min take out and clean with
                  compressed air, Automatic mode only need to operate the
                  cleaning switch on touch screen within 1 minute to
                  complete;<br />
                  12. Glue tank/metering pump/glue pipe heating function
                  (optional);<br />
                  13. Vacuum control keeps thin fluids from dripping;<br />
                  14. Unique control system which will not influenced by air
                  pressure factor, ensures optimal dispensing performance;
                  <br />
                  15. XYZ axis can be programmed to dispense glue along desired
                  patterns. <br />
                  <br />

                  Application Area:<br />

                  <br />
                  1. Electrical Products: Electronic parts, integrated circuits,
                  circuit board solder paste, fixed and dust and moisture
                  protection, LCD liquid crystal screen, speakers, headphone,
                  audio, LED, optical lens, SMD transformers, relays.<br />
                  2. Communication Products: Keypad, mobile phone casing
                  bonding, radio, telephone, Computers, MP4, electronic toys,
                  chassis bonding, switches, connectors, plug cable. <br />

                  <br />
                  Advantage of dispenser robot<br />
                  Dispensing robots automate most fluid assembly processes
                  ensuring fast and repeatable deposits without mess, waste or
                  error. For making repeat dots, beads, arcs and circles with
                  any fluid, adhesive or paste. Easy to setup and run and these
                  systems are supplied complete.<br />

                  <br />
                  i.Specification: <br />
                  <br />
                  <img
                    src="../../../../assets/images/product/main/production_equipement/Glue_Dispensing_Machine/5pgb700.jpg"
                    alt="img-1"
                    class="img-fluid mx-auto d-block rounded"
                  />

                  <br />
                  Suitable Material: For waterproof, adhesive, fixed
                  two-component adhesive.<br />

                  Such as: Crystal or less additive glue --Silicone, epoxy,
                  polyurethane(PU), etc.<br />

                  Applications:LED products ,Crafts,Electronic components<br />

                  Such as:Wall washer light, LED bar, LED strip, Ads module,
                  Sensor, Photovoltaic module, Photovol­taic inverter .etc
                  <br />
                  <br /><br />

                  <img
                    src="https://v4-upload.goalsites.com/141/editor_1536215501_aa.jpg"
                    alt="img-1"
                    class="img-fluid mx-auto d-block rounded"
                  />
                </div>
              </b-tab>
              <b-tab title="The Working Principle">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    Liquid A & B are kept in two tanks separately. There are two
                    sets of metering pumps inside the machine, draining out
                    liquids individually from tank A & B. Liquid A & B mix with
                    each other in the mixer pipe and dispensed out according to
                    the programmable data.
                    <img
                      src="https://v4-upload.goalsites.com/141/editor_1529630830_%E5%9B%BE%E7%89%871.png"
                      alt="img-1"
                      class="img-fluid mx-auto d-block rounded"
                    />
                  </div>
                </div>
              </b-tab>
              <b-tab title="Other Information">
                <div class="row" style="font-size: 14px">
                  1. Working Area: 700x700x80mm <br />
                  2.Application products: LED display modules<br />
                  3.High precision valve: high speed, glue off clean, no glue
                  drawing, no dropping.<br />
                  4. Double Cylinder plunger pump: No glue backflow, high speed,
                  glue continuously, long life time.<br />
                  5. Mix glue A & B more evenly; no more or no less for agent
                  B.<br />
                  <br />
                  Name: Fully Automatic Glue Dispensing Machine<br />
                  Model: PGB-700<br />
                  Advised Glue: two-component silicone, epoxy, PU, etc.<br />
                  Ratio: 1:1 to 10:1 adjustable<br />
                  Glue Color: colored<br />
                  Glue Type: includes high percentage of additives, abrasive<br />
                  Customer’s Products: LED Screen ( display)
                </div>
                <div class="row">
                  <img
                    src="https://v4-upload.goalsites.com/141/editor_1536138709_aa.jpg"
                    alt="img-1"
                    class="img-fluid mx-auto d-block rounded"
                  />
                </div>
                <div class="row" style="font-size: 14px">
                  Ratio between the base agent and hardener: 1:1 or 10:1<br />
                  Abrasive. The base agent includes percentage of additives and
                  fillings such as aluminum oxide, or silica powder, in order to
                  increase the hardness or heat conduction <br />
                  The mixed liquid is abrasive, cause big friction to the
                  pump.<br />
                  Need to vacuum to remove the air bubble inside<br />
                  Not easy to mix the two components evenly.<br />
                  Vacuum: to remove the air bubble inside the adhesive;<br />
                  Self-cleaning；<br />
                  Heating: pre-heat the resin before used<br />
                  Dynamic Mixing Method to mix evenly;<br />
                  Daheng Patent Metering Pump: anti-abrasive, long life span;
                  high precision;<br />
                  Multi-nozzle: to improve capacity.<br />
                  High Precision Valve;<br />
                  Quality & Strict position control system.<br />
                  Multi-head nozzle available.
                </div>
              </b-tab>
            </b-tabs>
            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  data() {
    return {
      product1: true,
      product2: false,
      product3: false,
      product4: false,
      product5: false,
    };
  },
  components: {
    Header,

    Footer,
  },
  methods: {
    wehed() {
      (this.product1 = true),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false),
        (this.product5 = false);
    },
    thnine() {
      (this.product1 = false),
        (this.product2 = true),
        (this.product3 = false),
        (this.product4 = false);
      this.product5 = false;
    },
    tletha() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = true),
        (this.product4 = false);
      this.product5 = false;
    },
    arb3a() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = true);
      this.product5 = false;
    },
    khamssa() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false);
      this.product5 = true;
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>